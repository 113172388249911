<template>
  <!-- 

    TODO: Not Found
https://www.etusersgroup.org/wp-content/uploads/etug-restricted/2015meeting/018%20-%20ARMD%20Ignition%20System%20Presentation%20EDE.pdf
https://www.etusersgroup.org/wp-content/uploads/etug-restricted/2015meeting/026%20-%202015%20History%20of%20Detonation%20Critical%20Diameter%20Height%20-%20Boyd%20Hill.pdf
 -->
  <div
    class="meeting px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full pb-30">
        <TitleComponent
          class="py-8"
          title="October 2015 Meeting"
          size="large"
        />
        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              ATTENDEES
            </h2>
            <p class="font-bold mb-4">
              Below is a list of some of the entities represented at the
              meeting:
            </p>
            <ul class="list-disc p-light ml-10 mb-2">
              <li>Applied Research Associates, Inc – Tyndall AFB</li>
              <li>El Dorado Engineering</li>
              <li>Signature Science, LLC</li>
              <li>Orbital ATK – ABL, Bacchus, Promontory, and Elkton</li>
              <li>Vista Outdoor</li>
              <li>Canadian Explosives Research Laboratory</li>
              <li>DHS S&amp;T Transportation Security Laboratory</li>
              <li>Energetic Materials Research and Testing Center</li>
              <li>Los Alamos National Laboratory</li>
              <li>Naval Research Laboratory</li>
              <li>Oak Ridge National Laboratory</li>
              <li>Pacific Scientific Energetic Materials Co.</li>
              <li>SMS/Tooele Army Depot Lab</li>
              <li>TNO – Netherlands National Lab</li>
              <li>Sandia National Laboratory</li>
              <li>Sporting Arms and Ammunition Manufacturers’ Institute</li>
            </ul>
            <img src="@/assets/meeting/2015-min.jpg" />
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <div class="mb-6">
              <h2
                class="mb-6"
                style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
              >
                MEETING PROCEEDINGS
              </h2>
              <div
                style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
                class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
                role="tabpanel"
              >
                <div class="panel-heading pl-6">
                  <h4
                    class="panel-title toggle"
                    style="position:relative;"
                    data-fontsize="16"
                    data-lineheight="22"
                  >
                    <a
                      @click="showContent = showContent === 1 ? false : 1"
                      :class="{ open: showContent === 1 }"
                      style="cursor:pointer;"
                      aria-expanded="false"
                      aria-selected="false"
                      aria-controls="811950d795ab93cb4"
                      role="tab"
                      data-toggle="collapse"
                    >
                      <span
                        class="fusion-toggle-icon-wrapper"
                        aria-hidden="true"
                      >
                        <i class="fa-fusion-box"></i>
                      </span>
                      <span
                        class="fusion-toggle-heading font-bold"
                        style="color:#ef9a3d;"
                        >2015 Meeting Proceedings</span
                      >
                    </a>
                  </h4>
                </div>
                <transition name="fade">
                  <div
                    id="811950d795ab93cb4"
                    class="panel-collapse collapse"
                    v-if="showContent === 1"
                  >
                    <div
                      class="flex flex-col panel-body toggle-content fusion-clearfix pt-6"
                    >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F001%20-%20Opening%20Remarks%20-%20Bob%20Ford.pdf?alt=media&token=f788b850-7109-41e0-946d-363e78b4c900"
                        >001 – Opening Remarks – Bob Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F002%20-%20Review%20of%20Round%20Robin%20Objectives%20and%20Approach%20-%20Clint%20Guymon.pdf?alt=media&token=5d1b79d0-60c0-4827-9d8c-468b5bb2b6bc"
                        >002 – Review of Round Robin Objectives and Approach –
                        Clint Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F005%20-%20Load%20Verification%20of%20the%20ABL%20Friction%20Tester%20-%20Jason%20Phillips.pdf?alt=media&token=07091214-eebe-4614-be90-03f4464c209b"
                        >005 – Load Verification of the ABL Friction Tester –
                        Jason Phillips</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F006%20-%20Machine%20Maintenance%20Requirements%20-%20Jason%20Ford.pdf?alt=media&token=c9d0c8eb-977d-4110-aa0e-f15f62a58159"
                        >006 – Machine Maintenance Requirements – Jason Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F007%20-%20MBOM%20Impact%20and%20the%20Intermediate%20Weight%20-%20Clint%20Guymon.pdf?alt=media&token=5b0e4743-5fdb-4317-8696-2d77f784e74b"
                        >007 – MBOM Impact and the Intermediate Weight – Clint
                        Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F008%20-%20Current%20Trigger%20-%20Clint%20Guymon.pdf?alt=media&token=b3caeb63-f3ac-4963-a909-b14ec77b3139"
                        >008 – Current Trigger – Clint Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F009%20-%20TSL%20Projects%20Relating%20to%20Small-Scale%20Sensitivity%20Testing%20-%20David%20Hernandez.pdf?alt=media&token=e7d7e43d-fa2e-4fe9-9cfd-66770e109ea6"
                        >009 – TSL Projects Relating to Small-Scale Sensitivity
                        Testing – David Hernandez</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F010%20-%20SBAT%2C%20VTS%2C%20Taliani%20Presentation%20-%20Jason%20Ford.pdf?alt=media&token=201f231e-919a-4317-84b7-a66d74733d00"
                        >010 – SBAT, VTS, Taliani Presentation – Jason Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F011web%20-%20Particulate%20Jetting%20Speeds%20-%20Clint%20Guymon.pdf?alt=media&token=fbf3dd73-23a8-411b-80a8-49a6c963dcc2"
                        >011 – Particulate Jetting Speeds – Clint Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F012%20-%20Review%20of%20GoDetect%20%26%20Patent%20Status%20-%20Clint%20Guymon.pdf?alt=media&token=79574069-7d0d-4ae4-8e67-6f2edb51b7bd"
                        >012 – Review of GoDetect &amp; Patent Status – Clint
                        Guymon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F013web%20-%20BAM%20Friction%20with%20High-Speed%20Video%20-%20Troy%20Gardner.pdf?alt=media&token=395f0c04-a679-407a-84a3-ee10140135f5"
                        >013 – BAM Friction with High-Speed Video – Troy
                        Gardner</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F014%20-%20ETUG2015_UnconfinedPackageTest%20-%20Richard%20Bowes.pdf?alt=media&token=f591c141-c9e7-4a81-9614-64878d6de740"
                        >014 – UnconfinedPackageTest – Richard Bowes</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F015%20-%205.6%202014_PrimedCambric%20-%20Richard%20Bowes.pdf?alt=media&token=842c4de7-8a64-4f5e-8738-c75424187813"
                        >015 – PrimedCambric – Richard Bowes</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F016%20-%20Firing%20System%20Standards%20%26%20Review%20-%20Ryan%20Dittmar.pdf?alt=media&token=a83f1939-3089-40b0-8a50-f733352d5f2f"
                        >016 – Firing System Standards &amp; Review – Ryan
                        Dittmar</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F017web%20-%20TEAD%20Firing%20System%20-%20Roger%20Hale.pdf?alt=media&token=32faa9f0-14f0-4622-8aa9-736240cf77f2"
                        >017 – TEAD Firing System – Roger Hale</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F018%20-%20ARMD%20Ignition%20System%20Presentation%20%20EDE.pdf?alt=media&token=757225be-ed55-4d7f-8cc8-873d3c08c48c"
                        >018 – ARMD Ignition System Presentation – EDE</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F019%20-%20Firing%20System%20Hazards%20Analysis%20-%20Ryan%20Dittmar.pdf?alt=media&token=bc793e79-7353-4ffe-96e4-e0363ec9d3c6"
                        >019 – Firing System Hazards Analysis – Ryan Dittmar</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F020web%20-%20Critical%20Height%20Presentation%20-%20Troy%20Gardner.pdf?alt=media&token=a27468ba-7157-47e4-bff7-f0f8d84f293b"
                        >020 – Critical Height Presentation – Troy Gardner</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F021%20-%20Test%20Methods%20Matrix%20Update%20and%20Status%20-%20Bob%20Ford.pdf?alt=media&token=03e06167-0e9c-4f33-b2c7-7b07affdb19d"
                        >021 – Test Methods Matrix Update and Status – Bob
                        Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F022%20-%20Example%20and%20Review%20of%20Test%20Procedures%20and%20Hazards%20Analyses%20for%20Standard%20Tests%20-%20Troy%20Gardner.pdf?alt=media&token=1fa666ac-c558-4816-9773-e0a8e6c3eb0c"
                        >022 – Example and Review of Test Procedures and Hazards
                        Analyses for Standard Tests – Troy Gardner</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F023%20-%20EEL%20ISO%2017025%20Accreditation%20Challenges-ETUG%202015_1021%20-%20Dawnyel%20N.%20Verrett.pdf?alt=media&token=95259f59-b2a2-4410-a1d8-1fa55e20f67f"
                        >023 – EEL ISO 17025 Accreditation Challenges-ETUG
                        2015_1021 – Dawnyel N. Verrett</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F024%20-%20Process%20Hazards%20Analysis%20and%20the%20Test%20Methods%20Matrix%20-%20Bob%20Ford.pdf?alt=media&token=42aa2968-e79e-4166-b728-4ad0f8f46a90"
                        >024 – Process Hazards Analysis and the Test Methods
                        Matrix – Bob Ford</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F025%20-%20Designing%20Tools%20and%20Tests%20for%20In-Process%20-%20Larry%20Lyon.pdf?alt=media&token=1b606895-90d0-4a5a-948e-4763c25248c9"
                        >025 – Designing Tools and Tests for In-Process – Larry
                        Lyon</a
                      >
                      <a
                        class="mb-2 link"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2015%2F026%20-%202015%20History%20of%20Detonation%20%20Critical%20Diameter%20Height%20-%20Boyd%20Hill.pdf?alt=media&token=dd310f29-b7f4-43a0-a2db-7cba662b4705"
                        >026 – 2015 History of Detonation Critical Diameter
                        Height – Boyd Hill</a
                      >
                    </div>
                  </div>
                </transition>
              </div>
              <p class="p-light">
                These files are for participants of the ET Users Group. As such
                please consider the information as ‘approved for internal use
                only. Feel free to distribute this information within your
                company or group as needed. If individuals or organizations
                outside your company or group desire access to this information,
                please
                <router-link class="link" to="/contact-us"
                  >Contact Us</router-link
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss">
.meeting {
  .fusion-button.button-1 {
    border-radius: 0px;
  }

  .panel-title a .fa-fusion-box:before {
    position: absolute;
    left: -2rem;
    top: 2px;
    content: url("../../assets/plus-solid.svg");
    filter: invert(70%) sepia(90%) saturate(601%) hue-rotate(326deg)
      brightness(94%) contrast(100%);
  }

  .panel-title a.open .fa-fusion-box:before {
    content: url("../../assets/minus-solid.svg");
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .btn:hover {
    background-color: #464d5f !important;
  }
}
</style>
